import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../config";

const usersUrl = ({complete, form}) => {
    return `${API_URL}users${complete ? '?complete=true':''}${form ? `&form=${form}`:''}`
}

const userDataUrl = (id) => {
    return `${API_URL}user-data/${id}`
}

const updateUserDataUrl = () => {
    return `${API_URL}updateUser/`
}

const getPublicContent = () => {
    return axios.get(API_URL + "all");
};

const getUsers = () => {
    return axios.get(this.usersUrl(), { headers: authHeader() })
};

const getUser = (id) => {
    return axios.get(this.userDataUrl(id), { headers: authHeader() })
};

const UserService = {
    usersUrl,
    userDataUrl,
    updateUserDataUrl,
    getPublicContent,
    getUsers,
    getUser
};

export default UserService;

import {Box, CircularProgress} from "@mui/material";
import React from "react";

const CustomLoader = ({size}) => {
    return (
        <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress size={size}/>
        </Box>
    )
}

export default CustomLoader
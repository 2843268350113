export const fieldTypes = {
    TEXT: {type: "text"},
    EMAIL: {type: "email"},
    SELECT: {type: "select"},
    DATE: {type: "date"},
    DATE_TIME: {type: "datetime"},
    TIME: {type: "time"},
    RADIO_GROUP: {type: "radiogroup"},
    CHECK_BOX: {type: "checkbox", md: 12},
    TEXT_BOX: {type: "textbox"},
    URL: {type: "url", md: 12},
}

export const tempoRaggiungimentoLocation = {
    menoDiUnOra: {label: "Meno di 1h", short_label: "< 1h"},
    traUnaEDueOre: {label: "Tra 1h e 2h", short_label: "> 1h e < 2h"},
    piuDiDueOre: {label: "Più di 2h", short_label: "> 2h"}
}

export const onDownload = (filename, href) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = href;
    link.target = "_blank"
    link.click();
};
import React from "react";
import _ from "lodash";
import {fieldTypes, tempoRaggiungimentoLocation} from "../services/utils";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slide,
    TextField,
    Tooltip
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider, MobileDateTimePicker} from "@mui/x-date-pickers";
import Typography from "@mui/material/Typography";
import {ContentCopy, VideoCameraFront} from "@mui/icons-material";

const CustomField = ({userId, formik, field, setMessage}) => {
    //console.log("field:",field)
    const fieldType = _.find(fieldTypes, ['type', field.type])
    return(
        <Slide in={true} exit={true}
            children={<Grid item xs={12} md={field.md || fieldType?.md || 6}>
                {(() => {
                    switch (fieldType) {
                        case fieldTypes.RADIO_GROUP:
                            return <FormControl fullWidth>
                                <Typography variant={'body2'}>{field.label}</Typography>
                                <RadioGroup
                                    row
                                    value={field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    /*onChange={(event, checked) => {
                                        formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, checked)
                                        formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                    }}*/
                                    name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                >
                                    {
                                        field.values.map((radio) =>
                                            <FormControlLabel key={radio} value={radio} control={
                                                <Radio required/>} label={tempoRaggiungimentoLocation[radio]?.label}/>
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>
                        case fieldTypes.CHECK_BOX:
                            return <FormControl fullWidth>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                            name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                            disabled={field.precompiled}
                                            checked={field.getter ?
                                                (formik.values[field.getter][field.id] === true || formik.values[field.getter][field.id] === 'si')
                                                : formik.values[field.id]}
                                            onChange={(event, checked) => {
                                                formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, checked)
                                                formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                            }}
                                            onBlur={formik.handleBlur}
                                        />} label={field.label}/>
                                </FormGroup>
                            </FormControl>
                        case fieldTypes.SELECT:
                            return <FormControl variant="standard" fullWidth>
                                <FormHelperText>{field.label}</FormHelperText>
                                <Select
                                    //disabled={field.precompiled}
                                    label={field.label}
                                    id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                    name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                    //value={dati[field.id]}
                                    value={field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    //error={Boolean(formik.errors[field.id])}
                                    //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                    //helperText={field.label}
                                >
                                    {field?.values?.map(v =>
                                        <MenuItem value={v} key={v}>{v}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        case fieldTypes.DATE:
                        case fieldTypes.DATE_TIME:
                            return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDateTimePicker
                                    ampm={false}
                                    renderInput={(params) =>
                                        <TextField {...params} id="name-input"
                                                   variant={'standard'}/>}
                                    label={field.label}
                                    onChange={(date, selectionState) => {
                                        //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                        formik.setFieldValue(field.getter ? `${field.getter}.${field.id}` : field.id, date)
                                        formik.setFieldTouched(field.getter ? `${field.getter}.${field.id}` : field.id)
                                    }}
                                    disabled={field.precompiled}
                                    value={new Date(field.getter ? (formik.values[field.getter][field.id]._seconds*1000) : (formik.values[field.id]._seconds*1000)) || ''}
                                    inputFormat="dd/MM/yyyy - HH:mm"/>
                            </LocalizationProvider>
                        case fieldTypes.URL:
                            return <Box display={'flex'} justifyContent={'center'}>
                                <Button href={field.id === "urlStreaming" ?
                                    `https://edisonliveshow.it/live/${userId}`
                                    : field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                        target={'_blank'}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                        }}
                                        endIcon={<VideoCameraFront/>}
                                >
                                    Link {field.id === "urlStreaming" ? "streaming" : "al video"}
                                </Button>
                                <Tooltip title={"Copia link"}>
                                    <IconButton color={'primary'} onClick={async (event) => {
                                        event.stopPropagation()
                                        await navigator.clipboard.writeText(field.id === "urlStreaming" ?
                                            `https://edisonliveshow.it/live/${userId}`
                                            : field.getter ? formik.values[field.getter][field.id] : formik.values[field.id])
                                    }}>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        default:
                            return <TextField disabled={field.precompiled}
                                              id={field.getter ? `${field.getter}.${field.id}` : field.id}
                                              name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                //value={dati[field.id]}
                                              value={field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              label={field.label}
                                              variant={"standard"}
                                              sx={{width:'100%'}}/>
                    }
                })()}
            </Grid>}
        />)
}

export default CustomField
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {
    Box,
    Chip,
    Container,
    Fab,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Popper,
    Tab,
    Tabs,
    Typography,
    useTheme
} from "@mui/material";
import logo from '../assets/images/logo.png'
import bkg from '../assets/images/bkg.jpg'
import {DataGrid, itIT} from '@mui/x-data-grid'
import UserService from "../services/user.service";
import CustomGridToolbar from "../components/CustomGridToolbar";
import useAxios from "axios-hooks";
import ColumnsService from "../services/columns.service";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";
import {ExpandLess, ExpandMore, Refresh} from "@mui/icons-material";
import _ from 'lodash'

const forms = {
    CALL_TO_ACTION: {label: "Call to action", index: 0, id: "callToActionform"},
    PARTECIPATE: {label: "Partecipazione", index: 1, id: "partecipateForm"},
    VIDEO: {label: "Buoni Propositi", index: 2, id: "urlVideo"},
    STREAMING: {label: "Partecipazione Streaming", index: 3, id: "partecipateOnline"}
}

const Home = () => {
    let navigate = useNavigate()
    const theme = useTheme()

    const [activeTab, setActiveTab] = React.useState(0);
    const handleChangeActiveTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const [onlyCompleteFlag, setOnlyCompleteFlag] = useState(true)

    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({complete: onlyCompleteFlag}), {method: "GET", useCache: false}
    )

    const users = useMemo(() => {
        if (!allUsers)
            return []
        else return allUsers.filter(function (o) {
            return true//!o.roles// && !o.test
        })
    }, [allUsers])

    const [{data: config, loading: loadingConfig}, refetchConfig] = useAxios(ColumnsService.columnsUrl(), {
        method: "GET", useCache: false
    })

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    useEffect(() => {
        if (config)
            setColumnVisibilityModel(config.defaultVisibilityModel)
    }, [config])

    const usersPerForm = useMemo(() => {
        if (!users)
            return null

        const res = []
        res[forms.CALL_TO_ACTION.index] = _.filter(users, ['callToActionForm.submitted', true])
        res[forms.PARTECIPATE.index] = _.filter(users, ['partecipateForm.submitted', true])
        res[forms.VIDEO.index] = _.filter(users, function (o) {
            return !!o.urlVideo
        })
        res[forms.STREAMING.index] = _.filter(users, ['partecipateOnline', true])
        return res
    }, [users])

    const [filteredUsers, reports]: GridRowsProp = useMemo(() => {
        if (!usersPerForm)
            return [null, null]

        const _filteredUsers = _.orderBy(usersPerForm[activeTab],
            activeTab === 0 ? 'callToActionForm.completedAt._seconds'
                : activeTab === 1 ? 'partecipateForm.completedAt._seconds'
        : activeTab === 2 ? 'uploadedAt._seconds'
        : 'updatedAt._seconds', 'desc')
        let _reports = {
            'Utenti totali': _filteredUsers.length,
        }

        if (forms.CALL_TO_ACTION.index === activeTab) {
            let avvenimento = []
            _filteredUsers.forEach((user) => {
                if (user.callToActionForm?.avvenimento) {

                    const index = _.findIndex(avvenimento, function (o) {
                        return o.label === user.callToActionForm?.avvenimento
                    })
                    if (index === -1)
                        avvenimento.push({label: user.callToActionForm?.avvenimento, counter: 1})
                    else {
                        avvenimento[index] = {...avvenimento[index], counter: ++avvenimento[index].counter}
                    }
                }
            })
            const avvenimentiFiltered = _.filter(Object.values(avvenimento), function (o) {
                return o.counter > 1
            })

            _reports = {
                ..._reports,
                'Disponibili alle riprese': _.filter(_filteredUsers, ['callToActionForm.disponibile', true]).length,
                //'Avvenimento più votato': `${avvenimentiFiltered?.label} (${avvenimentiFiltered?.counter||0})`,
                'Avvenimenti': _.orderBy(avvenimentiFiltered, 'counter', 'desc'),
            }
        } else if (forms.PARTECIPATE.index === activeTab) {
            _reports = {
                ..._reports,
                'Partecipanti': _.filter(_filteredUsers, ['partecipateForm.partecipo', 'si']).length
            }
        } else if (forms.VIDEO.index === activeTab) {
            _reports = {
                ..._reports,
                'Video': _.filter(_filteredUsers, function (o) {
                    return !!o.urlVideo
                }).length
            }
        }

        return [_filteredUsers, _reports]
    }, [usersPerForm, activeTab])

    const columns = useMemo(() => {
        return ColumnsService.getColumns(activeTab)
    }, [activeTab])

    const [pageSize, setPageSize] = useState(10)

    const [openSuccess, setOpenSuccess] = React.useState(false);

    const handleSaveConfiguration = (newModel) => {
        setTimeout(function () {
            ColumnsService.saveDefaultVisibilityModel(newModel)
                .catch((err) => {
                    console.log("err:", err)
                    //setMessage({show: true, loading: false, text: "Configurazione non salvata", severity: "error"})
                })
                .finally(() => setColumnVisibilityModel(newModel))
        }, 200);
    }

    const anchorRef = React.useRef()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleToggleAvenimenti = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openAvvenimenti = Boolean(anchorEl);
    const id = openAvvenimenti ? 'simple-popper' : undefined;

    return (
        <main>
            <Box style={{
                /**background: theme.palette.primary.main, */backgroundImage: `url(${bkg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <Container maxWidth={false}>
                    <div style={{display: 'flex', width: '100%', maxWidth: '190px'}}>
                        <img src={logo} width={'100%'}
                             style={{margin: '0.2rem 0', filter: 'brightness(0) invert(1)'}}/>
                    </div>
                </Container>
            </Box>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}} maxWidth={false}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>Elenco Iscrizioni</Typography>
                    {/*<Tooltip arrow title={"Mostra solo le registrazioni completate"}>
                        <FormControlLabel
                            activeTab="start"
                            componentsProps={{typography: {variant: 'subtitle2'}}}
                            control={<Switch
                                checked={onlyCompleteFlag}
                                onChange={() => setOnlyCompleteFlag(!onlyCompleteFlag)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Solo completate"
                            labelPlacement="start"
                        />
                    </Tooltip>*/}
                </Box>
                <div style={{display: 'flex', height: 670, marginTop: '1rem'}}>
                    <div style={{flexGrow: 1}}>
                        <Tabs value={activeTab} onChange={handleChangeActiveTab} aria-label="disabled tabs example">
                            {
                                Object.values(forms).map((form) =>
                                    <Tab key={form.index}
                                         label={`${form.label} (${usersPerForm[form.index]?.length})`}/>
                                )
                            }
                        </Tabs>
                        <Box m={1} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                            {
                                Object.entries(reports || []).map(([key, value], i) => {
                                    if (key === 'Avvenimenti') {
                                        return <div ref={anchorRef} key={i}>
                                            <Chip aria-describedby={id}
                                                  disabled={loadingUsers || loadingConfig}
                                                  variant={'filled'} label={`${key}`} sx={{marginRight: 0.5}}
                                                  onClick={handleToggleAvenimenti}
                                                  icon={openAvvenimenti ? <ExpandLess/> : <ExpandMore/>}/>
                                            <Popper
                                                id={id}
                                                anchorEl={anchorEl}
                                                open={openAvvenimenti}
                                                onClose={handleToggleAvenimenti}
                                            >
                                                <Paper elevation={4} sx={{p: 1}}>
                                                    <List dense>
                                                        {
                                                            value.map((avvenimento, i) => {
                                                                return <ListItem key={i}>
                                                                    <ListItemText>
                                                                        <Typography>{avvenimento.label}</Typography>
                                                                    </ListItemText>
                                                                    <ListItemSecondaryAction>
                                                                        <Chip label={avvenimento.counter} size={'small'}
                                                                              color={'primary'}/>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            })
                                                        }
                                                    </List>
                                                </Paper>
                                            </Popper>
                                        </div>
                                    }
                                    return <Chip key={i} variant={'filled'} label={`${key}: ${value || 0}`}
                                                 sx={{marginRight: 0.5}}/>
                                })
                            }
                        </Box>
                        <DataGrid
                            localeText={{
                                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                                toolbarExport: "Esporta colonne visibili"
                            }}
                            loading={loadingUsers || loadingConfig}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                handleSaveConfiguration(newModel)
                            }
                            disableSelectionOnClick
                            onRowClick={(params, event, details) => {
                                navigate("/user/" + params.row.id, {state: {activeTab: activeTab}});
                                // TODO: Show row details
                            }}
                            components={{
                                Toolbar: () => {
                                    return <CustomGridToolbar/>
                                }
                            }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={!loadingConfig ? (columns || []) : []}
                            rows={filteredUsers || []}
                            //checkboxSelection={true}
                        />
                    </div>
                </div>
                <Fab //type="submit"
                    onClick={fetchUsers}
                    color={'primary'}
                    size={"small"}
                    sx={{
                        top: 'auto',
                        right: '20px',
                        bottom: '40px',
                        left: 'auto',
                        position: 'fixed',
                        zIndex: 1010,
                    }}>
                    <Refresh/>
                </Fab>
            </Container>
        </main>
    )
}

export default Home

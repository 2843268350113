import {createTheme} from "@mui/material";
import {itIT} from '@mui/material/locale';

export const edisonTheme = createTheme({
    /*typography: {
        fontFamily: ['Walsheim']
    },*/

    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    ":disabled": {
                        color: "lightGray",
                        textDecoration: "none",
                        cursor: "default",
                        "&:hover": {
                            textDecoration: "none"
                        }
                    }
                }
            }
        },
        MuiCard: {
            variants: [
                {
                    props: {variant: 'login'},
                    style: {
                        width: '100%',
                        maxWidth:'37.5rem',
                        margin:'2rem auto',
                        height:'100%',
                        borderRadius: '2rem',
                        border: 0,
                        padding:'1rem 2rem 3rem 2rem',
                        backgroundColor: 'rgba(255,255,255,0.8)'
                    },
                },
            ]
        },
        MuiLinearProgress: {
            styleOverrides:{
                root:{
                    width:'100% !important'
                }
            }
        }
    },

    palette: {
        mode: "light",
        primary: {
            main: '#E94E0F'
        },
        //divider: '#E94E0F'
    },
    itIT,
});
import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'

const ProtectedRoute = ({children, user }) => {

    if (!user) {
        return <Navigate to="/login" replace />;
    } else {
        const expiry = (JSON.parse(atob(user.split('.')[1]))).exp;
        if (expiry * 1000 < Date.now()) {
            return <Navigate to="/login" replace />;
        }
    }

    return children ? children : <Outlet/>;
};

export default ProtectedRoute

import React, {useState} from "react";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {Box, Button, Card, Container, Fab, Grid, Snackbar, Typography, useTheme} from "@mui/material";
import logo from '../assets/images/logo.png'
import UserService from "../services/user.service";
import {ArrowBack, Save} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import useAxios from "axios-hooks";
import CustomLoader from "../components/CustomLoader";
import formStructureEdison from '../data/formStructureEdison.json'
import CustomField from "../components/CustomField";
import {useFormik} from "formik";
import _ from 'lodash'
import bkg from "../assets/images/bkg.jpg";
import ColumnsService from "../services/columns.service";

const User = () => {
    const {id} = useParams()
    const {state} = useLocation()
    const {activeTab: activeTabIndex} = state
    //console.log("activeTabIndex:", activeTabIndex)
    const theme = useTheme()
    let navigate = useNavigate()

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})

    const [{data: userData, loading: loadingUserData, error: errorUserData}, fetchUser] = useAxios(
        UserService.userDataUrl(id), {method: "GET", useCache: false}
    )

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    const formik = useFormik({
        initialValues: userData,
        enableReinitialize: true,
    });

    const isValid = () => {
        return formik.dirty && formik.isValid
    }

    const handleSubmitForm = () => {
        if (isValid()) {
            //console.log("formik:",formik)
            const dto = {}
            Object.keys(formik.touched).forEach((touchedField) => {
                if (formik.values[touchedField] !== formik.initialValues[touchedField])
                    dto[touchedField] = formik.values[touchedField]
            })

            console.log("dto:", dto)
            if (!_.isEmpty(dto)) {
                //console.log("UPDATED:", dto)
                updateUser({
                    data: {data: dto, id: id},
                    url: UserService.updateUserDataUrl(),
                    method: "POST"
                })
                    .then(() => setMessage({
                        ...message,
                        show: true,
                        text: "Aggiornamento effettuato con successo",
                        severity: "success"
                    }))
                    .catch((err) => {
                        setMessage({
                            ...message,
                            show: true,
                            text: "Aggiornamento non andato a buon fine",
                            severity: "error"
                        })
                    })
            }
        }
    }

    return (
        <main>
            <Box style={{
                /**background: theme.palette.primary.main, */backgroundImage: `url(${bkg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <Container maxWidth={false}>
                    <div style={{display: 'flex', width: '100%', maxWidth: '190px'}}>
                        <img src={logo} width={'100%'}
                             style={{margin: '0.2rem 0', filter: 'brightness(0) invert(1)'}}/>
                    </div>
                </Container>
            </Box>
            <Container sx={{paddingY: '2rem'}}>
                <form onSubmit={handleSubmitForm}>
                    <Button LinkComponent={Link} to={"/"} variant="outlined" sx={{marginBottom: '2rem'}}
                            startIcon={<ArrowBack/>}>Torna all'elenco</Button>
                    {(loadingUserData || loadingUpdate) && <CustomLoader/>}
                    {/*userData &&
                        <Accordion defaultExpanded sx={{background: 'rgba(233,78,15,0.05)'}}>
                            <AccordionSummary expandIcon={<ExpandMore/>} sx={{alignItems: 'center'}}>
                                <Typography variant={"h6"} textAlign={'center'}>
                                    Attività su Mailchimp
                                </Typography>
                                <Chip label={`${userData.mailchimp?.activity?.length||0} azioni`}
                                      color={'primary'}
                                      size={'small'}
                                      sx={{margin: 0.5, marginLeft: 1}}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <CustomizedTimeline mailchimpActivity={userData.mailchimp?.activity}/>
                            </AccordionDetails>
                        </Accordion>
                    */}
                    <Box mt={2} style={{display: 'flex', alignItems: 'center'}}>
                        <Typography gutterBottom variant={"h4"}
                                    sx={{textAlign: 'left'}}>{userData?.nome} {userData?.cognome}</Typography>
                        {/*<Typography variant={"h5"}>Dati utente</Typography>*/}
                        {<Button
                            sx={{marginLeft: 'auto'}}
                            onClick={handleSubmitForm}
                            disabled={!isValid()}
                            startIcon={<Save/>}
                        >
                            SALVA
                        </Button>}
                    </Box>
                    {
                        userData && formik.values &&
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Typography variant={"h6"}
                                        sx={{textAlign: 'left'}}>{formStructureEdison.forms[activeTabIndex].label}</Typography>
                            <Grid container spacing={3}>
                                {
                                    ColumnsService.getFieldsFromForm(activeTabIndex).map((field, i) => {
                                        return <CustomField key={i} userId={id} formik={formik} field={field} setMessage={setMessage}/>
                                    })
                                }
                            </Grid>
                        </Card>

                    }

                    <Fab //type="submit"
                        onClick={handleSubmitForm}
                        disabled={!isValid()}
                        color={'primary'}
                        sx={{
                            top: 'auto',
                            right: '20px',
                            bottom: '20px',
                            left: 'auto',
                            position: 'fixed',
                            zIndex: 1010,
                            //backgroundColor: 'lightgrey',
                        }}>
                        <Save/>
                    </Fab>
                </form>
            </Container>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert} severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
        </main>
    )
}

export default User

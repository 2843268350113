import {Check, Close, ContentCopy, VideoCameraFront} from "@mui/icons-material";
import React from "react";
import forms from '../data/formStructureEdison.json'
import _ from 'lodash'
import {fieldTypes, tempoRaggiungimentoLocation} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";
import {Box, Button, IconButton, Tooltip} from "@mui/material";

const columnsUrl = () => {
    return `${API_URL}config`
}

const getColumnsVisibilityModel = () => {
    //TODO: prendere colonne visibili dal server
    // return axios.get(url)
    let columnsVisibilityModel = {}
    getFieldsFromForm().forEach((field) => {
        columnsVisibilityModel = {
            ...columnsVisibilityModel,
            [field.id]: field.defaultVisible
        }
    })
    return columnsVisibilityModel
};

const getColumsPreferences = () => {
    return axios.get(columnsUrl(), { headers: authHeader() })
    //return columnsPreferences.columsToExport
};

const saveColumsToExport = (columns) => {
    return axios.post(columnsUrl(), {defaultExportColumns: columns}, {headers: authHeader()})
};

const saveDefaultVisibilityModel = (columns) => {
    return axios.post(columnsUrl(), {defaultVisibilityModel: columns}, {headers: authHeader()})
};

const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    },
    {
        "id": "sede",
        "label": "Sede",
        "type": "text",
        "defaultVisible": true
    },
]

const getFieldsFromForm = (formIndex) => {
    return commonFields.concat(forms.forms[formIndex].fields)
}

const getColumns = (formIndex) => {
    const cols = (getFieldsFromForm(formIndex)).map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.label || field.id || (_.startCase(field)),
            flex: 1,
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }}

        const formType = _.find(fieldTypes, ['type', field.type])
        switch (formType) {
            case fieldTypes.DATE:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000))
                            : params.value ?
                                new Date(params.value)
                                : "").toLocaleDateString('it-IT');
                    },
                }
            case fieldTypes.DATE_TIME:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000))
                            : params.value ?
                                new Date(params.value)
                                : "").toLocaleString('it-IT');
                    },
                }
            case fieldTypes.TIME:
                return {
                    ...baseField,
                    // type: 'date', // TODO: capire
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000))
                            : params.value ?
                                new Date(params.value)
                                : "").toLocaleTimeString('it-IT');
                    },
                }
            case fieldTypes.CHECK_BOX:
                return {
                    ...baseField,
                    width: '50px',
                    flex:0.5,
                    valueFormatter: (params) => {
                        if (params.value || params.value === "si") {
                            return 'Sì';
                        } else return 'No';
                    },
                    renderCell: (params) => (params.value === true || params.value === 'si')
                        ? <Check sx={{color: 'green'}}/>
                        : (params.value === false || params.value === 'no')
                            ? <Close color={'disabled'}/>
                            : '-'
                }
            case fieldTypes.URL:
                return {
                    ...baseField,
                    renderCell: (params) => {
                        return <Box display={'flex'} justifyContent={'center'}>
                            <Button href={params.field === "urlStreaming" ?
                                `https://edisonliveshow.it/live/${params.id}`
                                : params.value}
                                    target={'_blank'}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                    }}
                                    endIcon={<VideoCameraFront/>}
                            >
                                Link {params.field === "urlStreaming" ? "streaming" : "al video"}
                            </Button>
                            <Tooltip title={"Copia link"}>
                                <IconButton color={'primary'} onClick={async (event) => {
                                    event.stopPropagation()
                                    await navigator.clipboard.writeText(params.field === "urlStreaming" ?
                                        `https://edisonliveshow.it/live/${params.id}`
                                        : params.value)
                                }}>
                                    <ContentCopy fontSize={'small'}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                }
            case fieldTypes.RADIO_GROUP:
                return {
                    ...baseField,
                    valueFormatter: (params) => {
                        return tempoRaggiungimentoLocation[params.value]?.short_label
                    }
                }
            default:
                return baseField
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumnsVisibilityModel,
    getFieldsFromForm,
    getColumns,
    getColumsPreferences,
    saveColumsToExport,
    saveDefaultVisibilityModel
};

export default ColumnsService;